import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRightArrowLeft, faArrowUp, faCalendarDays, faCopy } from "@fortawesome/free-solid-svg-icons";
import { ProtocolParser, parseIMEI } from "complete-teltonika-parser";
import { useCopyToClipboard } from 'usehooks-ts'

import { socket } from "../../socket";
import { useMediaQuery } from "@mui/material";
import { formatDateTimeSec, sleep } from "../../utils/globals";

import TreeGrid from "react-gridtree";

import "./Traffic.css";
import CustomDatePicker from "../commons/datepicker/CustomDatePicker";
import { getTeltonikaParams, getTraffics } from "../../services/axios";
import { startOfToday } from "date-fns";
import { message, notification } from "antd";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

const Traffic = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const getData = useLocation();

  const data = {
    Name: getData?.state?.Name,
    device: getData?.state?.device,
    deviceModel: getData?.state?.deviceModel,
    deviceImei: getData?.state?.deviceImei,
  }

  const [parameters, setParameters] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [selectedItem, setSelectedItem] = useState(null);
  const [treeData, setTreeData] = useState([]);


  const [startDate, setStartDate] = useState(startOfToday());
  const [endDate, setEndDate] = useState(new Date());
  const [copiedText, copy] = useCopyToClipboard();

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // get all parameters
    getTeltonikaParams(data.deviceModel)
      .then(result => {
        if (result) {
          setParameters(result);
        }
      });

    function onUpdateDeviceStatus(deviceData) {
      if (deviceData.deviceImei == data.deviceImei) {
        // loadDataList();
      }
    }
    socket.on('updateDeviceStatus', onUpdateDeviceStatus);

    // Cleanup function to clear the interval
    return () => {
      // clearInterval(interval);
      socket.off('updateDeviceStatus', onUpdateDeviceStatus);
    }
  }, [getData]);

  useEffect(() => {
    loadDataList();
  }, [startDate, endDate]);

  const loadDataList = async (page = 1) => {
    setIsLoading(true);
    const res = await getTraffics({
      deviceImei: data?.deviceImei,
      startDate, endDate,
      page: page,
      size: pageSize
    });

    if (res?.status === 200) {
      if (page === 1) {
        setDataList(res.data.result);
      } else {
        setDataList([
          ...dataList,
          ...res?.data?.result
        ]);
      }
      setTotalCount(res?.data?.totalCount);
      setPageNum(page);
      setIsLoading(false);
    }
  }

  const scrollRef = useBottomScrollListener(async () => {
    const totalPage = Math.ceil(totalCount / pageSize);
    if (totalPage > pageNum) {
      await loadDataList(pageNum + 1);
    }
  });


  useEffect(() => {
    if (isLoading) {
      messageApi.open({
        type: 'loading',
        content: 'Action in progress..',
        duration: 0,
      });
    } else {
      messageApi.destroy();
    }
  }, [isLoading]);


  const handleCopy = (e, item) => {
    e.stopPropagation();
    copy(item.raw)
      .then(() => {
        notification.info({
          description: "Copied!",
        });
      })
      .catch(error => { })
  }

  const handleSelect = async (item) => {
    setSelectedItem(item);
    setTreeData([]);
    await sleep(100);

    let tree = [];

    if (item.kind == 0 && item.raw.length == 34) {
      // imei response

    } else {
      let parsed = null;
      try {
        parsed = new ProtocolParser(item.raw);
      } catch (err) { }

      if (parsed) {
        tree.push({ name: 'Preamble', length: 4, value: parsed.Preamble });
        tree.push({ name: 'AVL Data Length', length: 4, value: parsed.Data_Length });
        tree.push({ name: 'Codec ID', length: 1, value: parsed.CodecID });
        tree.push({ name: 'Quantity1', length: 1, value: parsed.Quantity1 });

        let dataNode = { name: 'Data', length: 'var', value: '', children: [] };
        if (parsed.CodecType == "data sending") {
          // status response


          dataNode.children.push({ name: 'AVL Data Count', length: 1, value: parsed.Content.AVL_Datas.length });
          for (const item of parsed.Content.AVL_Datas) {
            let avlNode = { name: 'AVL Data', length: 'var', children: [] };
            avlNode.children.push({ name: 'Timestamp', length: 8, value: formatDateTimeSec(item.Timestamp) });
            avlNode.children.push({ name: 'Priority', length: 1, value: item.Priority });

            let gpsNode = { name: 'GPS Element', length: 15, children: [] };
            gpsNode.children.push({ name: 'Longitude', length: 4, value: item.GPSelement.Longitude });
            gpsNode.children.push({ name: 'Latitude', length: 4, value: item.GPSelement.Latitude });
            gpsNode.children.push({ name: 'Altitude', length: 2, value: item.GPSelement.Altitude });
            gpsNode.children.push({ name: 'Angle', length: 2, value: item.GPSelement.Angle });
            gpsNode.children.push({ name: 'Satellites', length: 1, value: item.GPSelement.Satellites });
            gpsNode.children.push({ name: 'Speed', length: 2, value: item.GPSelement.Speed });
            avlNode.children.push(gpsNode);

            let ioNode = { name: 'I/O Element', length: 'var', children: [] };
            ioNode.children.push({ name: 'Event ID', length: 1, value: item.IOelement.EventID });
            ioNode.children.push({ name: 'Element Count', length: 1, value: item.IOelement.ElementCount });

            const headings = [];
            for (const key in item.IOelement.Elements) {
              let avlInfo = parameters?.find(avlItem => avlItem.avlId == key);
              if (!avlInfo) {
                continue;
              }

              // search heading
              let headingItem = headings.find(hItem => hItem.name == avlInfo.heading);
              if (!headingItem) {
                headingItem = { name: avlInfo.heading, children: [] };
                headings.push(headingItem);
              }
              headingItem.children.push({ name: `${avlInfo.avlId} : ${avlInfo.avlName}`, length: avlInfo.bytes, value: item.IOelement.Elements[key], desc: avlInfo.desc });
            }
            ioNode.children.push(...headings);
            avlNode.children.push(ioNode);
            dataNode.children.push(avlNode);
          }

        } else {
          // GPRS command response
          dataNode.children.push({ name: "type", length: 1, value: parsed.Content.type });
          dataNode.children.push({ name: "isResponse", length: 1, value: (parsed.Content.isResponse ? 'true' : 'false') });
          dataNode.children.push({ name: "responseStr", length: 'var', value: parsed.Content.responseStr });
        }

        tree.push(dataNode);
        tree.push({ name: 'Quantity2', length: 1, value: parsed.Quantity2 });
        tree.push({ name: 'CRC16', length: 4, value: parsed.CRC });
      }
    }
    setTreeData(tree);
  }


  return (
    <div className="traffics px-4 py-5">
      {contextHolder}
      <div className="d-flex justify-content-start align-items-center gap-2">
        <div
          onClick={() => navigate("/Telemetry", { state: data })}
          className="sub1-telemetry-setting-div1 d-flex justify-content-center align-items-center py-1 cursor-pointer"
          style={{ backgroundColor: 'rgba(122, 125, 139, 1)' }}
        >
          <p className="text-center mb-0 text-white">Telemetry</p>
        </div>

        <div
          className="sub1-telemetry-setting-div1 d-flex justify-content-center align-items-center py-1 cursor-pointer gap-5"
        >
          <p className="text-center mb-0 text-white">Traffic</p>
          <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ fontSize: 16, color: 'white', transform: 'rotate(90deg)' }} />
        </div>
      </div>
      <div className="telemetry-setting-div2 w-100 d-flex align-items-center justify-content-start my-4">
        <div className="sub1-telemetry-setting-div2 d-flex flex-column justify-content-evenly p-3 text-white">
          <p className="mb-0">{data.Name}</p>
          <p className="mb-0">Device: {data.device} {data.deviceModel}</p>
          <p className="mb-0">IMEI: {data.deviceImei}</p>
        </div>
        <div className="d-flex flex-grow-1 flex-row justify-content-center gap-5 align-items-center" style={{ fontSize: 16 }}>
          <div className="field-input position-relative">
            <CustomDatePicker
              placeholderText="From"
              selectedDate={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}
            />
            <FontAwesomeIcon
              className="position-absolute top-0 end-0 mt-2 me-3"
              icon={faCalendarDays} />
          </div>

          <div className="field-input position-relative">
            <CustomDatePicker
              placeholderText="To"
              selectedDate={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              maxTime={new Date()}
            />
            <FontAwesomeIcon
              className="position-absolute top-0 end-0 mt-2 me-3"
              icon={faCalendarDays} />
          </div>
        </div>
      </div>
      <div className="telemetry-items-wrapper container-fluid w-100" style={isMobile ? { height: "calc(100svh - 20rem)" } : {}}>
        <div className="row align-items-stretch h-100 p-3">
          <div className="col-sm-3 bg-white rounded-5 overflow-auto mh-100 p-2" ref={scrollRef}>
            {dataList.map((item, index) => (
              <div key={index}
                onClick={() => handleSelect(item)}
                className={`raw-item w-100 rounded-4 p-3 bg-body-tertiary d-flex justify-content-center align-items-center mb-2 cursor-pointer ${selectedItem?._id == item._id && 'active'}`}
              >
                <div className="pe-2">
                  <FontAwesomeIcon icon={faCopy}
                    style={{ fontSize: 18, color: 'rgba(30, 107, 151, 1)' }} className="cursor-pointer"
                    onClick={(e) => handleCopy(e, item)}
                  />
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex  justify-content-between align-items-center mb-2" style={{ color: (item.kind == 1 ? 'rgba(248, 151, 29, 1)' : 'rgba(204, 51, 194, 1)') }}>
                    <span>{formatDateTimeSec(item.createdAt)}</span>
                    {item.kind == 1 ?
                      <span>
                        <span className="text-warning me-2">Data Sent</span> <FontAwesomeIcon icon={faArrowUp} />
                      </span>
                      :
                      <span>
                        <span className="me-2">Data Received</span> <FontAwesomeIcon icon={faArrowDown} />
                      </span>
                    }
                  </div>
                  <div className="text-break text-uppercase">
                    {item.raw}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-sm-9 rounded-5 d-flex  mh-100 ">
            <div className="bg-white w-100 h-100 rounded-5 overflow-auto py-2 px-5">
              {treeData.length > 0 &&
                <TreeGrid
                  data={treeData}
                  options={{
                    fields: [
                      {
                        property: 'name',
                        displayName: 'Name',
                        width: '20%'
                      },
                      {
                        property: 'length',
                        displayName: 'Length',
                        width: '10%'
                      },
                      {
                        property: 'value',
                        displayName: 'Value',
                        width: '20%'
                      },
                      // {
                      //   property: 'hex',
                      //   displayName: 'Hex',
                      // },
                      {
                        property: 'desc',
                        displayName: 'Description',
                        format: (value) => {
                          return (
                            value ?
                              <div dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, '<br/>') }}></div> : ''
                          )
                        }
                      }
                    ]
                  }}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Traffic;
