import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

import "./Telemetry.css";
import TelemetryItem from "./TelemetryItem";
import { addTelemetryItem, changeTelemetryOrder, deleteTelemetryItem, getTelemetries, getTeltonikaParams } from "../../services/axios";
import { Input } from "antd";
import { useMediaQuery } from "@mui/material";
import { socket } from "../../socket";

const ICCID_AVLID = 999999;
let clickInInput = false;

const Telemetry = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dropdownRef = useRef(null);

  const getData = useLocation();
  const [keyword, setKeyword] = useState("");
  const [parameters, setParameters] = useState([]);
  const [filteredParameters, setFilteredParameters] = useState([]);
  const [telemetries, setTelemetries] = useState([]);

  const data = {
    Name: getData?.state?.Name,
    device: getData?.state?.device,
    deviceModel: getData?.state?.deviceModel,
    deviceImei: getData?.state?.deviceImei,
  }

  const handleAddItem = async (avlId, avlName, heading) => {
    const result = await addTelemetryItem(data.deviceImei, avlId, avlName, heading);
    if (result.status === 200) {
      getTelemetriesInfo();
    }
    clickInInput = false;
    setKeyword("");
  }
  const handleDeleteItem = async (avlId) => {
    const result = await deleteTelemetryItem(data.deviceImei, avlId);
    if (result.status === 200) {
      getTelemetriesInfo();
    }
  }

  const getTelemetriesInfo = async (isLive = true) => {
    const result = await getTelemetries(data.deviceImei, isLive);
    if (result?.status === 200) {
      setTelemetries(result.data.result);
    }
  }

  useEffect(() => {
    const filtered = parameters.filter(item => item.avlId == parseInt(keyword) || item.avlName?.toLowerCase().indexOf(keyword.toLowerCase()) != -1);
    setFilteredParameters(filtered);
  }, [keyword, parameters]);


  useEffect(() => {
    // get all parameters
    getTeltonikaParams(data.deviceModel)
      .then(result => {
        if (result) {
          const params = [...result, { avlId: ICCID_AVLID, avlName: "ICCID", type: "Unsigned", min: "", max: "" }]
          setParameters(params);
          setFilteredParameters(params);
        }
      });

    // get init data
    getTelemetriesInfo(false);
    getTelemetriesInfo();

    const handleDropdownHide = (event) => {
      if (clickInInput) {
        clickInInput = false;
        event.preventDefault();
        return false;
      }
      setKeyword("");
      return true;
    }
    if (dropdownRef && dropdownRef.current) {
      dropdownRef.current.addEventListener('hide.bs.dropdown', handleDropdownHide);
    }


    function onUpdateDeviceStatus(deviceData) {
      if (deviceData.deviceImei == data.deviceImei) {
        getTelemetriesInfo();
      }
    }
    socket.on('updateDeviceStatus', onUpdateDeviceStatus);


    // Cleanup function to clear the interval
    return () => {
      // clearInterval(interval);
      socket.off('updateDeviceStatus', onUpdateDeviceStatus);
      if (dropdownRef && dropdownRef.current) {
        dropdownRef.current.removeEventListener('hide.bs.dropdown', handleDropdownHide);
      }
    }
  }, [getData]);

  const getClassName = (dataId, dataValue) => {
    const pItem = parameters.find(pitem => pitem.avlId == dataId);
    if (pItem && pItem.min == 0 && pItem.max == 1) {
      return dataValue == 1 ? 'high' : 'low';
    }
    if (dataId == 69) {
      return dataValue == 1 ? 'high' : 'low';
    }
    return '';
  }

  const getDataValue = (dataId, dataValue) => {
    if (dataId == 16) { // total odometer
      dataValue = dataValue / 1000;
    }
    return dataValue;
  }

  const handleChangePosition = (sourceId, sourceIndex, targetIndex, targetId) => {
    if (!sourceIndex || !targetIndex) {
      return;
    }
    let newParams = [...telemetries];
    changeTelemetryOrder(data.deviceImei, telemetries[sourceIndex].dataId, targetIndex);
    const result = swap(newParams, sourceIndex, targetIndex);
    setTelemetries(result);
  }

  return (
    <div className="telemetry-setting-main px-4 py-5">
      <div className="d-flex justify-content-start align-items-center gap-2">
        <div className="sub1-telemetry-setting-div1 d-flex justify-content-center align-items-center py-1">
          <p className="text-center mb-0 text-white">Telemetry</p>
        </div>

        <div
          onClick={() => navigate("/Traffic", { state: data })}
          className="sub1-telemetry-setting-div1 d-flex justify-content-center align-items-center py-1 cursor-pointer gap-5"
          style={{ backgroundColor: 'rgba(122, 125, 139, 1)' }}
        >
          <p className="text-center mb-0 text-white">Traffic</p>
          <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ fontSize: 16, color: 'white', transform: 'rotate(90deg)' }} />
        </div>
      </div>
      <div className="telemetry-setting-div2 w-100 d-flex align-items-end justify-content-between my-4">
        <div className="sub1-telemetry-setting-div2 d-flex flex-column justify-content-evenly p-3 text-white">
          <p className="mb-0">{data.Name}</p>
          <p className="mb-0">Device: {data.device} {data.deviceModel}</p>
          <p className="mb-0">IMEI: {data.deviceImei}</p>
        </div>

        <div ref={dropdownRef} className="btn-add-widget d-flex justify-content-between align-items-center cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="/assets/addbtn.svg" alt="none" />
          <span>Add Widget</span>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
        <div className="dropdown-menu dropdown-menu-end px-3">
          <Input
            value={keyword}
            placeholder="avlId, avlName"
            onChange={(e) => setKeyword(e.target.value)}
            onMouseDown={() => { clickInInput = true; }}
            onMouseUp={() => { clickInInput = false; }}
          />
          <ul className="parameters-list">
            {filteredParameters && filteredParameters.map((item, index) => {
              let clsName = (telemetries?.findIndex(titem => titem.dataId == item.avlId) != -1) ? 'added' : '';
              return <a key={index} className={`dropdown-item ${clsName} border-bottom`} onClick={() => handleAddItem(item.avlId, item.avlName, item.heading)}>
                {item.avlId == ICCID_AVLID ? "N/A" : item.avlId} : {item.avlName}<br />
                <div className="text-end">({item.heading})</div>
              </a>;
            })}
          </ul>
        </div>
      </div>
      <div>
        <GridContextProvider onChange={handleChangePosition}>
          <div className="telemetry-items-wrapper"
            style={isMobile ? { height: "calc(100svh - 20rem)" } : {}}
          >
            <GridDropZone
              className="telemetry-items dropzone"
              boxesPerRow={isMobile ? 1 : 4}
              rowHeight={70}
              style={isMobile ? { height: `${70 * telemetries?.length}px`, width: "calc(100svw - 15rem)" } : { height: `${70 * Math.ceil(telemetries?.length / 4)}px` }}
            >
              {telemetries && telemetries.map((item, index) => (
                <GridItem className="grid-item" key={item.dataId}>
                  <TelemetryItem
                    key={item.dataId}
                    index={index}
                    id={item.dataId}
                    name={item.dataName}
                    heading={item.heading}
                    value={getDataValue(item.dataId, item.dataValue)}
                    updatedAt={item.updatedAt}
                    isDisabled={item.isDisabled}
                    onDelete={() => handleDeleteItem(item.dataId)}
                    className={getClassName(item.dataId, item.dataValue)}
                  />
                </GridItem>
              ))}
            </GridDropZone>
          </div>
        </GridContextProvider>
      </div>
    </div>
  );
};

export default Telemetry;
