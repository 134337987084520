import axios from "axios";

if (process.env.REACT_APP_DEV_MODE === "FALSE") {
  axios.defaults.baseURL = process.env.REACT_APP_URL;
} else {
  axios.defaults.baseURL = process.env.REACT_APP_DEV_URL;
}

axios.interceptors.request.use(function (config) {
  // document.body.classList.add('loading-indicator');
  return config
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  document.body.classList.remove('loading-indicator');
  return response;
}, function (error) {
  document.body.classList.remove('loading-indicator');
  return Promise.reject(error);
});


const getResponse = async (url, type, props) => {
  try {
    let result;
    if (type === "post") {
      result = await axios.post(url, props, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return result;
    } else {
      result = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return result;
    }
  } catch (error) {
    return error.response;
  }
};

const sendGet = async (url, props) => await getResponse(url, 'get', props);
const sendPost = async (url, props) => await getResponse(url, 'post', props);


const getUserNameList = async () => {
  return await sendPost("/auth/userIdList");
};

const signInUser = async (props) => {
  return await sendPost("/auth/login", props);
};
const signInUserAs = async (props) => {
  return await sendPost("/api/loginAs", props);
};

const resendVerifyEmail = async (email) => {
  return await sendPost("/auth/resendEmail", { email });
};

const validateUserName = async (userName) => {
  return await sendPost("/auth/validateUserName", { userName });
};

const userRegister = async (props) => {
  return await sendPost("/auth/signUp", props);
};

const resetPassword = async (props) => {
  return await sendPost("/auth/reset", props);
};

const getUser = async (props) => {
  return await sendPost("/api/users/getUser", props);
};

const activateUser = async (props) => {
  return await sendPost("/api/users/activateUser", props);
};

const getUsers = async () => {
  return await sendGet("/api/users/list");
};

const addUser = async (props) => {
  return await sendPost("/api/users/addUser", props);
};

const updateUser = async (props) => {
  return await sendPost("/api/users/updateUser", props);
};

const updateMe = async (props) => {
  return await sendPost("/api/users/updateMe", props);
};

const updatePassword = async (props) => {
  return await sendPost("/api/users/updatePassword", props);
};

const deleteUser = async (props) => {
  return await sendPost("/api/users/removeUser", props);
};

const uploadAvatar = async (file) => {
  let formDataFile = new FormData();
  formDataFile.append('avatar', file);
  return await sendPost("/api/common/uploadAvatar", formDataFile);
};

const getMapKeys = async () => {
  return await sendPost("/api/users/getMapKeys");
};

const assignUserVehicles = async (props) => {
  return await sendPost("/api/vehicles/assignUserVehicles", props);
};

const assignCompanyVehicles = async (props) => {
  return await sendPost("/api/vehicles/assignCompanyVehicles", props);
};

const getAllDevices = async (props) => {
  return await sendPost("/api/vehicles/show", props);
};

const createDevice = async (props) => {
  return await sendPost("/api/vehicles/create", props);
};

const updateDevice = async (props) => {
  return await sendPost("/api/vehicles/update", props);
};

const removeDevice = async (props) => {
  return await sendPost("/api/vehicles/remove", props);
};


const getHistoryList = async (props) => {
  return await sendPost("/api/vehicles/history", props);
};

const getHistoryDates = async (props) => {
  return await sendPost("/api/vehicles/history-dates", props);
};

const commandSetting = async (props) => {
  return await sendPost("/api/vehicles/sendCommand", props);
};

const commandSettingAll = async (props) => {
  return await sendPost("/api/vehicles/sendBatchCommand", props);
};

const getCommandSettings = async (props) => {
  return await sendPost("/api/vehicles/getCommandStatus", props);
};
const getCommandSettingsById = async (props) => {
  return await sendPost("/api/vehicles/getCommandStatusById", props);
};

const showComandQueue = async (props) => {
  return await sendPost("/api/vehicles/showCommand", props);
};

// get available teltonika avl parameters
const getTeltonikaParams = async (deviceModel) => {
  let key = "TP_" + deviceModel;
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
  const result = await sendGet(`/api/common/teltonikaParams/${deviceModel}`);
  if (result?.status === 200) {
    let info = result.data.result;
    localStorage.setItem(key, JSON.stringify(info));
    return info;
  }
  return null;
};

// get telemetry info
const getTelemetries = async (imei, isLive) => {
  return await sendPost("/api/vehicles/getTelemetries", {
    deviceImei: imei, isLive: isLive
  });
};

// add telemetry item for a device
const addTelemetryItem = async (imei, avlId, avlName, heading) => {
  return await sendPost("/api/vehicles/addTelemetryItem", {
    deviceImei: imei, avlId, avlName, heading
  });
};

// delete telemetry item for a device
const deleteTelemetryItem = async (imei, avlId) => {
  return await sendPost("/api/vehicles/deleteTelemetryItem", {
    deviceImei: imei, avlId
  });
};

// change telemetry order for a device
const changeTelemetryOrder = async (imei, avlId, index) => {
  return await sendPost("/api/vehicles/changeTelemetryOrder", {
    deviceImei: imei, avlId, index
  });
};

// get traffics
const getTraffics = async (props) => {
  return await sendPost("/api/vehicles/getTraffics", props);
};

// get all companies
const getCompanies = async () => {
  return await sendGet("/api/company/getAll");
};

const createCompany = async (props) => {
  return await sendPost("/api/company/create", props);
};

const updateCompany = async (id, props) => {
  return await sendPost("/api/company/update", {
    id, ...props
  });
};

const deleteCompany = async (id) => {
  return await sendPost("/api/company/remove", { id });
};

const activateCompany = async (props) => {
  return await sendPost("/api/company/activate", props);
};


const getAlertList = async (props) => {
  return await sendPost("/api/alerts/list", props);
};

const getUnreadAlertList = async (props) => {
  return await sendPost("/api/alerts/unreadList", props);
};

const getUnreadAlertCount = async () => {
  return await sendPost("/api/alerts/unreadCount");
};

const setAlertsRead = async (ids) => {
  return await sendPost("/api/alerts/setRead", { ids });
};

// report events
const exportReports = async (props) => {
  return await sendPost("/api/alerts/export", props);
};

// get geofences
const getGeofences = async () => {
  return await sendPost("/api/geofences/getAll");
};

// add geofence item
const createGeofence = async (name, deviceImeis, type, geometry) => {
  return await sendPost("/api/geofences/create", {
    name, deviceImeis, type, geometry
  });
};

// update geofence item
const updateGeofence = async (id, name, deviceImeis, type, geometry) => {
  return await sendPost("/api/geofences/update", {
    id, name, deviceImeis, type, geometry
  });
};

// delete geofence
const deleteGeofence = async (id) => {
  return await sendPost("/api/geofences/remove", {
    id
  });
};

// ============== ticket actions ===============
const getTicketList = async (props) => {
  return await sendPost("/api/tickets/list", props);
};

// add ticket item
const createTicket = async (subject, company, content, files) => {
  return await sendPost("/api/tickets/create", {
    subject, company, content, files
  });
};

// reply ticket
const replyTicket = async (id, content, files) => {
  return await sendPost("/api/tickets/reply", {
    id, content, files
  });
};

// delete ticket
const deleteTickets = async (ids) => {
  return await sendPost("/api/tickets/remove", {
    ids
  });
};

// update status
const updateTicketStatus = async (props) => {
  return await sendPost("/api/tickets/updateStatus", props);
};

const uploadTicketFile = async (file) => {
  try {
    let formDataFile = new FormData();
    formDataFile.append('file', file);
    return await sendPost("/api/common/uploadTicketFile", formDataFile);
  } catch (error) {
    return error.response;
  }
};


// =================== rule actions =======================
// get all companies
const getRules = async () => {
  return await sendGet("/api/rules/getAll");
};

const createRule = async (props) => {
  return await sendPost("/api/rules/create", props);
};

const updateRule = async (id, props) => {
  return await sendPost("/api/rules/update", {
    id, ...props
  });
};

const deleteRule = async (id) => {
  return await sendPost("/api/rules/remove", {
    id
  });
};

const activateRule = async (props) => {
  return await sendPost("/api/rules/activate", props);
};

const addRuleVehicles = async (props) => {
  return await sendPost("/api/rules/addVehicles", props);
};

// ============== ACS Users actions ===============
const getACSUserList = async (props) => {
  return await sendPost("/api/acsusers/list", props);
};

// add or update item
const upsertACSUser = async (props) => {
  return await sendPost("/api/acsusers/upsert", props);
};


// delete ticket
const deleteACSUsers = async (ids) => {
  return await sendPost("/api/acsusers/remove", {
    ids
  });
};


export {
  userRegister,
  signInUser,
  signInUserAs,
  resendVerifyEmail,
  resetPassword,
  validateUserName,
  getUser,
  activateUser,
  uploadAvatar,
  getUsers,
  addUser,
  updateUser,
  deleteUser,
  updateMe,
  updatePassword,
  getMapKeys,
  getUserNameList,

  commandSetting,
  commandSettingAll,
  getCommandSettingsById,
  getCommandSettings,
  showComandQueue,

  getHistoryList,
  getHistoryDates,

  getAllDevices,
  createDevice,
  updateDevice,
  removeDevice,
  assignUserVehicles,
  assignCompanyVehicles,

  getTeltonikaParams,
  getTelemetries,
  addTelemetryItem,
  deleteTelemetryItem,
  changeTelemetryOrder,
  getTraffics,

  getCompanies,
  createCompany,
  updateCompany,
  deleteCompany,
  activateCompany,

  getAlertList,
  getUnreadAlertList,
  getUnreadAlertCount,
  setAlertsRead,
  exportReports,

  getGeofences,
  createGeofence,
  updateGeofence,
  deleteGeofence,

  getTicketList,
  createTicket,
  replyTicket,
  deleteTickets,
  updateTicketStatus,
  uploadTicketFile,

  getRules,
  createRule,
  updateRule,
  deleteRule,
  activateRule,
  addRuleVehicles,

  getACSUserList,
  upsertACSUser,
  deleteACSUsers,
};
