import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateMe, updatePassword, uploadAvatar } from "../../../services/axios";

import { Input, Modal, Select, notification } from "antd";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import { getCountries } from "../../../utils/globals";
import { UPDATE_USER } from "../../../redux/store/types";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const EditMe = () => {
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const CountryData = getCountries();

  const [isPwdModal, setIsPwdModal] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(user.phone);
  const [avatarPath, setAvatarPath] = useState(user.image);
  const [formData, setFormData] = useState({
    address: user.address,
    name: user.fname,
    username: user.lname,
    email: user.email,
    country: user.country,
  })
  const [curPassword, setCurPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cfmPassword, setCfmPassword] = useState("");

  const handleNumber = (e) => {
    setPhoneNumber(e)
  };

  const handleData = (e, name) => {
    setFormData({ ...formData, [name]: e.target.value });
  }

  const handleSubmit = () => {
    let msg = null;
    if (formData.name.length === 0) {
      msg = "Please input your name!";
    } else if (formData.username.length === 0) {
      msg = "Please input your username!";
    } else if (formData.email.length === 0) {
      msg = "Please input your email!";
    } else if (phoneNumber.length === 0) {
      msg = "Please input your mobile number!";
    } else if (formData.country.length === 0) {
      msg = "Please input your country";
    }

    if (msg != null) {
      return notification.warning({ description: msg });
    }
    final()
  }

  const final = async () => {
    const sendData = {
      fname: formData.name,
      lname: formData.username,
      email: formData.email,
      phone: phoneNumber,
      country: formData.country,
      address: formData.address,
      image: avatarPath,
    }
    var res = await updateMe(sendData);

    if (res?.status == 200) {
      notification.success({
        description: res.data.message,
      });

      dispatch({
        type: UPDATE_USER,
        payload: res.data.result
      });

      navigate("/Dashboard");
    }
    else {
      notification.error({
        description: res.data.message,
      });
    }
  }

  const onChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const result = await uploadAvatar(selectedFile);
      if (result.status == 200) {
        setAvatarPath(result.data.filePath);
      } else {
        notification.error({
          description: "Error occured!",
        });
      }
    }
  }

  const handleUpdatePassword = async (e) => {
    let msg = null;
    if (curPassword.length === 0) {
      msg = "Please input current password!";
    } else if (newPassword.length === 0) {
      msg = "Please input new password!";
    } else if (newPassword !== cfmPassword) {
      msg = "Confirm password is not equal!";
    }

    if (msg != null) {
      return notification.warning({ description: msg });
    }

    const res = await updatePassword({ curPassword, newPassword });
    if (res?.status == 200) {
      notification.success({ description: "Password updated successfully!" });
      setIsPwdModal(false);
    } else {
      notification.error({ description: res.data.message });
    }
  }

  return (
    <>
      <div className="add-user-main">
        <p>Update My Account</p>
        <div className='add-user-div1 d-flex justify-content-start flex-column p-5 mx-5'>
          <div className='person-add-user-sub1-add-user-div1 d-flex justify-content-center position-relative mb-5'>
            <input
              type='file'
              className='personfile position-absolute'
              accept='image/*'
              onChange={onChange}
            />
            <img
              crossOrigin='*'
              src={avatarPath ? process.env.REACT_APP_URL + '/' + avatarPath : '/assets/common_user.png'}
              alt="none"
              className="person-add-user object-fit-cover"
            />
          </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
            <div className='d-flex flex-column'>
              <label>Name</label>
              <input className='normal-input' value={formData.name} type='text' onChange={e => handleData(e, 'name')} />
            </div>
          <div className='sub2-subsub1-sub1-add-user-div1 d-flex flex-column position-relative'>
              <label>Username</label>
              <input className='normal-input' value={formData.username} type='text' onChange={e => handleData(e, 'username')} disabled />
            </div>
          </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
            <div className='d-flex flex-column'>
              <label>Email</label>
              <input className='normal-input' value={formData.email} type='email' onChange={e => handleData(e, 'email')} />
            </div>
            <div className='d-flex flex-column'>
              <label>Mobile No.</label>
              <div className='normal-input border-0 p-0'>
                <PhoneNumberInput
                  value={phoneNumber}
                  onChange={handleNumber}
                />
              </div>
            </div>
          </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
            <div className='d-flex flex-column position-relative'>
              <label>Country & City</label>
              <div className="normal-input p-0 border-0">
                <Select
                  showSearch
                  allowClear
                  size={isMobile ? "small" : "large"}
                  className="w-100"
                  placeholder="City & Country"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={CountryData?.map((item) => ({
                    label: `${item.city}, ${item.country}`, value: `${item.city}, ${item.country}`
                  }))}
                  value={formData.country}
                  onChange={(value) => setFormData({ ...formData, "country": value })}
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <label>Address</label>
              <input className='normal-input' type='text' value={formData.address} onChange={e => handleData(e, 'address')} />
            </div>
          </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
            <div className='d-flex flex-column'>
              <label>User Type</label>
              <select className='normal-input' type='text' name="role" onChange={() => { }} value={user.role} >
                <option value={user.role}>{user.role}</option>
              </select>
            </div>
            <div className='d-flex flex-column'>
              <label>Company</label>
              <select className='normal-input' type='text' name="company" value={user.company?._id} onChange={() => { }} >
                <option value={user.company?._id}>{user.company?.name}</option>
              </select>
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-3 gap-4'>
            <div className='normal-input' style={{ border: "none" }}>
              <button className="tab-button mt-3 mt-md-0"
                style={{ background: "#005EEC", minWidth: "24rem" }}
                onClick={() => setIsPwdModal(true)}>
                Change Password
              </button>
            </div>
            <div className='normal-input' style={{ border: "none" }}>&nbsp;</div>
          </div>
          <div className="subsub5-sub1-add-user-div1 d-flex justify-content-center">
            <button onClick={() => handleSubmit()}>Save</button>
          </div>
        </div>
      </div>

      <Modal
        centered
        open={isPwdModal}
        onOk={() => setIsPwdModal(false)}
        onCancel={() => setIsPwdModal(false)}
        width={500}
        style={{ maxWidth: "100%" }}
        className='update-password-modal'
        footer={false}
      >
        <div className='pt-4 d-flex flex-column align-items-center gap-4'>
          <p className="red-black fw-bold">Change Password</p>
          <div className='d-flex flex-column'>
            <label>Current Password</label>
            <Input.Password size='large' className='normal-input' value={curPassword} onChange={e => setCurPassword(e.target.value)} autoComplete='cur-passwd3' />
          </div>
          <div className='d-flex flex-column'>
            <label>New Password</label>
            <Input.Password size='large' className='normal-input' value={newPassword} onChange={e => setNewPassword(e.target.value)} autoComplete='new-passwd3' />
          </div>
          <div className='sub2-subsub1-sub1-add-user-div1 d-flex flex-column position-relative'>
            <label>Confirm Password</label>
            <Input.Password size='large' className='normal-input' value={cfmPassword} onChange={e => setCfmPassword(e.target.value)} autoComplete='new-cfmpasswd3' />
          </div>
          <div className="mt-3">
            <button className="tab-button"
              style={{ minWidth: "15rem", fontSize: "1.2rem" }}
              onClick={handleUpdatePassword}>
              Update Password
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditMe;
