import io from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET_URL;

var userDeviceImeis = [];

export const socket = io(URL, {
  path: "/socket/",
  autoConnect: false,
  secure: true,
  rejectUnauthorized: false,
  auth: (cb) => {
    let userInfo = null;
    if (localStorage.getItem("user")) {
      userInfo = JSON.parse(localStorage.getItem("user"));
    }
    console.log("socket connect auth", userInfo);
    cb({
      token: {
        id: userInfo?.id,
        email: userInfo?.email,
        timeOffset: new Date().getTimezoneOffset(),
      }
    });
  }
});

export const sendAddDevicesMsg = (devices) => {
  let deviceImeis = devices.map(item => item.deviceImei);
  if (deviceImeis.length > 0 && JSON.stringify(userDeviceImeis) != JSON.stringify(deviceImeis)) {
    // console.log("status", socket.connected, deviceImeis);
    // if (socket.connected) {
    socket.emit('addDevices', { deviceImeis });
    // console.log('[sendAddDevicesMsg]', deviceImeis); 
    // }
  }
  userDeviceImeis = deviceImeis;
}

export const sendAddDevicesToUserMsg = (deviceImeis, toUserId) => {
  socket.emit('addDevicesToUser', { toUserId, deviceImeis });
}

export const sendCommandMsg = (commandId, deviceImei = null, commandStr = null) => {
  socket.emit('sendCommand', { commandId, deviceImei, commandStr });
}

export const sendBatchCommandMsg = (deviceImei) => {
  socket.emit('sendBatchCommand', { deviceImei });
}

export const sendRulesUpdatedMsg = () => {
  socket.emit('rulesUpdated', {});
}