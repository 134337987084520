import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { notification, Select } from "antd";
import { getCountries } from "../../../utils/globals";
import axios from "axios";
import { userRegister, resendVerifyEmail, validateUserName } from "../../../services/axios";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import "./style.css";

const Signup = () => {
  const CountryData = getCountries();
  const navigate = useNavigate();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [fname, setFname] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameValid, setUserNameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [city, setCity] = useState(null);
  const [phone, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [fade, setFade] = useState(false);
  const [isMailSended, setIsMailSended] = useState(false);

  const [isInvalied, setIsInvalied] = useState({
    fName: { status: false, message: "" },
    uName: { status: false, message: "" },
    mNum: { status: false, message: "" },
    email: { status: false, message: "" },
    city: { status: false, message: "" },
    password: { status: false, message: "" },
    cpassword: { status: false, message: "" },
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/Dashboard");
    }

    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCity(data.city + ", " + data.country_name);
        setMobile(data.country_calling_code);
      })
      .catch((error) => {
        // console.log(error);
      });

  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (userName && !userNameValid) {
      setIsInvalied({
        ...isInvalied,
        uName: {
          status: true,
          message: "User Name not available",
        },
      });
    }
  }, [userName, userNameValid]);

  useEffect(() => {
    const validatieUserName = debounce(async () => {
      if (userName) {
        if (!/^\w+$/.test(userName)) {
          setUserNameValid(false);
          setIsInvalied({
            ...isInvalied,
            uName: {
              status: true,
              message: "User Name Not Valid",
            },
          });
        } else {
          var response = await validateUserName(userName);
          if (response && response.status === 200) {
            setUserNameValid(response.data);
            setIsInvalied({
              ...isInvalied,
              uName: {
                status: !response.data,
                message: "User Name Not Available",
              },
            });
          }
        }
      }
    }, 1500);

    validatieUserName();

    return () => {
      validatieUserName.cancel();
    };
  }, [userName]);

  const resendMail = async (email) => {
    const result = await resendVerifyEmail({ email });
    if (result) {
      notification.success({
        placement: "topRight",
        description: "Email verification was sent.",
        duration: 3,
      });
    } else {
      notification.error({
        placement: "topRight",
        description: "Email verification was not sent.",
        duration: 3,
      });
    }
    setFade(false);
  };

  const signUp = async () => {
    setFade(true);
    const registerData = {
      fname: fname,
      userName: userName,
      email: email,
      password: password,
      phone: phone,
      city: city,
    };

    const invaild = {
      fName: { status: false, message: "" },
      uName: { status: false, message: "" },
      mNum: { status: false, message: "" },
      email: { status: false, message: "" },
      city: { status: false, message: "" },
      password: { status: false, message: "" },
      cpassword: { status: false, message: "" },
    };
    let total_flag = false;
    if (password.length < 6) {
      invaild.password.status = true;
      invaild.password.message = "Password should Not be less than 6";
      total_flag = true;
    } else {
      if (cpassword === "") {
        invaild.cpassword.status = true;
        invaild.cpassword.message = "Confirm Password is requierd";
        total_flag = true;
      } else {
        if (cpassword !== password) {
          invaild.cpassword.status = true;
          invaild.cpassword.message = "Password Not Matching";
          total_flag = true;
        }
      }
    }

    if (fname === "") {
      invaild.fName.status = true;
      invaild.fName.message = "First Name is required";
      total_flag = true;
    }
    if (userName === "") {
      invaild.uName.status = true;
      invaild.uName.message = "User Name is required";
      total_flag = true;
    } else if (!/^\w+$/.test(userName)) {
      invaild.uName.status = true;
      invaild.uName.message = "User Name is invalid";
      total_flag = true;
    }
    if (city === "") {
      invaild.city.status = true;
      invaild.city.message = "City Name is required";
      total_flag = true;
    }
    if (phone === "") {
      invaild.mNum.status = true;
      invaild.mNum.message = "Phone Number is required";
      total_flag = true;
    }
    if (email === "") {
      invaild.email.status = true;
      invaild.email.message = "Email is required";
      total_flag = true;
    }

    if (!total_flag) {
      var res = await userRegister(registerData);
      if (res?.status === 200) {
        notification.success({
          placement: "topRight",
          description: "SignUp Successful!",
          duration: 4,
        });
        setIsMailSended(true);
      } else {
        notification.error({
          placement: "topRight",
          description: res.data.message,
          duration: 3,
        });
      }
    } else {
      setIsInvalied(invaild);
    }
    setFade(false);
  };

  return (
    <div className="auth" id="auth">
      <div className="auth-left col-md-8 px-0">
        <img src="/assets/log.png" alt="logo" />
      </div>
      <div className="auth-right col-md-4 px-0 py-4">
        <img className="mb-5 main-logo" src="/assets/mainLogo.svg" alt="main-logo" />
        <div className="sub1-auth-right">
          <div className="main-form d-flex justify-content-center align-items-center" style={{ padding: (isMailSended ? '30px' : '10px') }}>
            <div className="subsub1-sub1-auth-right">
              {!isMailSended && (
                <>
                  <h3 style={{ marginBottom: '2rem' }}>SignUp</h3>

                  {isInvalied.fName.status && (
                    <div className="input-container d-flex mb-2" style={{ borderBottom: "0px", height: "0px" }}>
                      <div className="error-box">
                        {isInvalied.fName.message}
                      </div>
                    </div>
                  )}
                  <div className="input-container d-flex mb-4">
                    <img src="/assets/logname.svg" alt="none" />
                    <input
                      placeholder="First Name"
                      type="text"
                      onChange={(e) => {
                        setFname(e.target.value);
                        setIsInvalied({
                          ...isInvalied,
                          fName: { status: false, message: "" },
                        });
                      }}
                      autoComplete="new-fname"
                    />
                  </div>
                  {isInvalied.uName.status && (
                    <div className="input-container d-flex mb-2" style={{ borderBottom: "0px", height: "0px" }}>
                      <div className="error-box">
                        {isInvalied.uName.message}
                      </div>
                    </div>
                  )}
                  <div className="input-container d-flex mb-4 position-relative">
                    <img src="/assets/logUser.svg" alt="none" />
                    <input
                      placeholder="Username"
                      type="text"
                      value={userName}
                      autoComplete="new-lname"
                      onChange={(e) => {
                        setUserName(e.target.value.toLowerCase());
                        setIsInvalied({
                          ...isInvalied,
                          uName: { status: false, message: "" },
                        });
                      }}
                      onKeyDown={e => {
                        if (!/\w/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {userName && userNameValid && (
                      <img
                        className="my-auto position-absolute me-0"
                        src={"/assets/valid.svg"}
                        style={{
                          width: "1.8rem",
                          right: "0.5rem",
                          bottom: "0.7rem",
                        }}
                        alt="none"
                      />
                    )}
                  </div>
                  {isInvalied.email.status && (
                    <div className="input-container d-flex mb-2" style={{ borderBottom: "0px", height: "0px" }}>
                      <div className="error-box">
                        {isInvalied.email.message}
                      </div>
                    </div>
                  )}
                  <div className="input-container d-flex mb-4">
                    <img src="/assets/logmail.svg" alt="none" />
                    <input
                      placeholder="Email"
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setIsInvalied({
                          ...isInvalied,
                          email: { status: false, message: "" },
                        });
                      }}
                      autoComplete="new-email"
                    />
                  </div>
                  {isInvalied.mNum.status && (
                    <div className="input-container d-flex mb-2" style={{ borderBottom: "0px", height: "0px" }}>
                      <div className="error-box">{isInvalied.mNum.message}</div>
                    </div>
                  )}
                  <div className="input-container d-flex mb-4">
                    <img src="/assets/logmobile.svg" alt="none" />
                    <PhoneNumberInput
                      value={phone}
                      onChange={(val) => setMobile(val)}
                      placeholder="Mobile Number"
                    />
                  </div>
                  {isInvalied.city.status && (
                    <div className="input-container d-flex mb-2" style={{ borderBottom: "0px", height: "0px" }}>
                      <div className="error-box">{isInvalied.city.message}</div>
                    </div>
                  )}
                  <div className="input-container d-flex mb-4 position-relative city">
                    <img src="/assets/logcity.svg" alt="none" />
                    <Select
                      showSearch
                      allowClear
                      placeholder="City & Country"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={CountryData?.map((item) => ({
                        label: `${item.city}, ${item.country}`, value: `${item.city}, ${item.country}`
                      }))}
                      value={city}
                      onChange={(value) => setCity(value)}
                    />
                  </div>
                  {isInvalied.password.status && (
                    <div className="input-container d-flex mb-2" style={{ borderBottom: "0px", height: "0px" }}>
                      <div className="error-box">
                        {isInvalied.password.message}
                      </div>
                    </div>
                  )}
                  <div className="input-container d-flex mb-4">
                    <img src="/assets/logLock.svg" alt="none" />
                    <input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setIsInvalied({
                          ...isInvalied,
                          password: { status: false, message: "" },
                        });
                      }}
                    />
                  </div>
                  {isInvalied.cpassword.status && (
                    <div className="input-container d-flex mb-2" style={{ borderBottom: "0px", height: "0px" }}>
                      <div className="error-box" style={{ left: "230px" }}>
                        {isInvalied.cpassword.message}
                      </div>
                    </div>
                  )}
                  <div className="input-container d-flex mb-4">
                    <img src="/assets/logLock.svg" alt="none" />
                    <input
                      placeholder="Confirm Password"
                      type="password"
                      onChange={(e) => {
                        setCpassword(e.target.value);
                        setIsInvalied({
                          ...isInvalied,
                          cpassword: { status: false, message: "" },
                        });
                      }}
                      autoComplete="new-cfm"
                    />
                  </div>

                  <button className="py-1"
                    onClick={() => {
                      setFade(true);
                      signUp();
                    }}
                  >
                    {!fade ? (
                      "SignUp"
                    ) : (
                      <img src="/assets/loading.gif" className="imgloading" alt="none" />
                    )}
                  </button>
                  <span className="have-account" style={{ marginBottom: '1rem' }}>
                    Already have account!{" "}
                    <p style={{ textDecoration: "underLine", fontSize: "2rem", display: "inline", }} onClick={() => { navigate("/"); }}>
                      Login
                    </p>
                  </span>
                </>
              )}
              {isMailSended && (
                <div className="resend-email">
                  <h3>Signup Successful !</h3>
                  <p style={{ textAlign: "center", fontSize: 16, color: "#28A745", background: "#B2DEBC", padding: ".5rem 1rem", borderRadius: "1rem" }}>
                    Verification Email has been sent to below registered Email
                  </p>
                  <p style={{ fontSize: 16, color: "#ffffff", borderBottom: "1px solid #ffffff", }}>
                    {email}
                  </p>
                  <button className="py-1"
                    onClick={() => {
                      setFade(true);
                      resendMail(email);
                    }}
                  >
                    {!fade ? (
                      "Resend Email"
                    ) : (
                      <img src="/assets/loading.gif" className="imgloading" alt="none" />
                    )}
                  </button>
                  <button className="py-1" onClick={() => { navigate("/"); }}>
                    Login
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="subsub2-sub1-auth-right mt-5">
            <a href="https://play.google.com/store/apps/details?id=com.ayaz.techno&hl=en" target="_blank" rel="noreferrer">
              <img
                src="/assets/google_play.svg"
                alt="none"
                style={{ width: "20rem", height: "5.8rem" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
