import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { getCompanies } from "../../../services/axios";
import {
  SWITCH_MENU_VISIBLE,
  SWITCH_SIDEBAR_VISIBLE,
  UPDATE_DEVICE_STATUS,
} from "../../../redux/store/types";
import { getDevices } from "../../../redux/actions/devices";

import "./SideBar.css";
import { formatDateTime, getGooglePosUrl, sleep } from "../../../utils/globals";
import { Popover, Select, Tooltip, message, notification } from "antd";
import { cmdType } from "../../../common/common";
import { sendAddDevicesMsg, socket } from "../../../socket";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import ControlPanelAC from "./ControlPanelAC";
import ControlPanel from "./ControlPanel";

const connectStatus = [
  {
    id: 1,
    label: "Online",
    value: "Connected",
    icon: "/assets/Online.svg",
    color: "#28A745",
  },
  {
    id: 2,
    label: "Offline",
    value: "Not Connected",
    icon: "/assets/Offline.svg",
    color: "#D01400",
  },
  {
    id: 3,
    label: "Idle",
    value: "Idle",
    icon: "/assets/Idle.svg",
    color: "#F89823",
  },
];

const vehicleTypes = [
  {
    id: 1,
    label: "Car",
    icon: "/assets/Car.svg",
  },
  {
    id: 2,
    label: "Bike",
    icon: "/assets/Bike.svg",
  },
  {
    id: 3,
    label: "ACS",
    icon: "/assets/ACS.svg",
  },
];

let keepControlPanel = false;
let keepACSPanel = false;

const SideBar = (props) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const devices = useSelector((state) => state.devicesList.devices);
  const showMenu = useSelector((state) => state.global.showMenu);
  const showSidebar = useSelector((state) => state.global.showSidebar);

  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [connectedTag, setConnectedTag] = useState("");
  const [vehicleTypeTag, setVehicleTypeTag] = useState("");
  const [selectedVeh, setSelectedVeh] = useState(null);
  const [selectedVehData, setSelectedVehData] = useState(null);

  const [owners, setOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);

  const modalRef = useOutsideClick(() => {
    if (!keepControlPanel) {
      setSelectedVeh(null);
    }
  });


  useEffect(async () => {
    if (isAuthenticated) {
      keepControlPanel = false;
      keepACSPanel = false;

      socket.disconnect().connect();

      getCompanies()
        .then(res => {
          if (res.status == 200) {
            setOwners(res.data.result.map(item => ({ value: item._id, label: item.name })));
          }
        })
        .catch(err => { });

      (async () => {
        dispatch(getDevices(false));
        await sleep(100);
        dispatch(getDevices(true));
      })();
    }

  }, [isAuthenticated]);

  useEffect(() => {
    setSelectedVehData(
      devices?.filter((d) => d.deviceImei === selectedVeh)[0]
    );
  }, [selectedVeh]);

  useEffect(() => {
    if (devices?.length > 0) {
      var result = devices?.filter((item, index) =>
        (!connectedTag || (
          (connectedTag == 'Connected' && item.connected) ||
          (connectedTag == 'Not Connected' && !item.connected) ||
          (connectedTag == 'Idle' && item.idle && item.connected)
        )) &&
        (!vehicleTypeTag || item?.vehicleType === vehicleTypeTag)
      );

      if (searchText != "") {
        result = result.filter(item => (item.vehicleName?.toLowerCase().indexOf(searchText.toLowerCase()) != -1 || item.deviceImei?.indexOf(searchText) != -1));
      }
      if (selectedOwner) {
        result = result.filter(item => (item.company?._id == selectedOwner));
      }
      setFilteredVehicles(result);

      if (selectedVeh) {
        setSelectedVehData(
          devices?.filter((d) => d.deviceImei === selectedVeh)[0]
        );
      }

      // when gsmNumbers updated
      if (location.pathname == "/CommandsSettings") {
        const updatedDevice = devices?.find((d) => d.deviceImei === location.state.deviceImei);
        if (updatedDevice) {
          if (JSON.stringify(location.state.gsmNumbers) != JSON.stringify(updatedDevice.gsmNumbers)) {
            location.state.gsmNumbers = updatedDevice.gsmNumbers;
          }
        }
      }
    } else {
      setFilteredVehicles([]);
    }
  }, [connectedTag, vehicleTypeTag, devices, searchText, selectedOwner]);

  const handleShowInfo = (idx, e) => {
    e.stopPropagation();
  }

  const handleLocation = (item, e) => {
    e.stopPropagation();

    var lat = item?.lat;
    var lng = item?.lng;
    navigate("/Dashboard", {
      state: {
        point: { lat, lng }
      }
    });

    hideMobileMenu();
  };

  const handleNavigation = (item, e) => {
    e.stopPropagation();

    var lat = item?.lat;
    var lng = item?.lng;

    window.open(getGooglePosUrl(lat, lng), "_blank");
  };

  const handleTagClick = (value, type) => {
    switch (type) {
      case 0:
        setConnectedTag(value === connectedTag ? "" : value);
        break;
      case 1:
        setVehicleTypeTag(value === vehicleTypeTag ? "" : value);
        break;
      default:
        break;
    }
  };

  const hideMobileMenu = () => {
    dispatch({ type: SWITCH_SIDEBAR_VISIBLE, payload: false });
  }
  const handleMobileMenuControlClick = () => {
    dispatch({ type: SWITCH_SIDEBAR_VISIBLE, payload: !showSidebar });
    dispatch({ type: SWITCH_MENU_VISIBLE, payload: false });
  };

  useEffect(() => {
    if (isLoading) {
      messageApi.open({
        type: 'loading',
        content: loadingMsg,
        duration: 0,
      });
    } else {
      messageApi.destroy();
    }
  }, [isLoading, loadingMsg]);


  // ============ socket events ==============

  useEffect(() => {
    sendAddDevicesMsg(devices);
  }, [devices]);

  useEffect(() => {
    function onConnect() {
      if (devices?.length > 0) {
        sendAddDevicesMsg(devices);
      }
    }

    function onDisconnect() {
      sendAddDevicesMsg([]);
    }

    function onUpdateDeviceStatus(data) {
      // console.log(data);
      dispatch({
        type: UPDATE_DEVICE_STATUS,
        payload: data
      });
    }

    function onUpdateUserDevices(data) {
      notification.warning({
        message: 'info',
        description: `New Vehicle ${data?.vehicleNames?.join(', ')} has been assigned`,
      });
      dispatch(getDevices(false));
    }

    const onUpdateCommandResponse = async (cmdData) => {
      // console.log(cmdData);
      if (cmdData.cmdType == cmdType.EngineOn) {
        setIsLoading(false);
      }

      if (cmdData.matchingId == '32') {
        setIsLoading(false);
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('updateDeviceStatus', onUpdateDeviceStatus);
    socket.on('updateUserDevices', onUpdateUserDevices);
    socket.on('updateCommandResponse', onUpdateCommandResponse);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('updateDeviceStatus', onUpdateDeviceStatus);
      socket.off('updateUserDevices', onUpdateUserDevices);
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, []);


  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <>
      {contextHolder}

      {isMobile ? (
        <>
          {!showMenu && (
            <div className={`position-absolute d-flex justify-content-center align-items-center menu-icon w-100 ${showSidebar ? "up-arrow" : "down-arrow"}`}
              onClick={handleMobileMenuControlClick}
            >
              <img className="" src="/assets/arrow.svg" alt="arrow" />
            </div>
          )}
          {showSidebar && (
            <div className="position-absolute w-100 h-100">
              <div className="main-sidebar d-flex flex-column h-100 position-relative">
                <div className="filter d-flex flex-column my-5">
                  <div className="d-flex justify-content-between align-items-center px-5 mx-5 my-1">
                    {connectStatus.map((item) => (
                      <div
                        key={item.id}
                        className={`connected ${connectedTag === item.value && "selected"} d-flex flex-column align-items-center justify-content-center py-1`}
                        style={{ color: item.color }}
                        onClick={() => handleTagClick(item.value, 0)}
                      >
                        <img className="my-1" src={item.icon} alt="none" />
                        <p className="my-0">{item.label}</p>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex justify-content-between align-items-center px-5 mx-5 my-1">
                    {vehicleTypes.map((item) => (
                      <div
                        key={item.id}
                        className={`connected ${vehicleTypeTag === item.label && "selected"} d-flex flex-column align-items-center justify-content-center py-2`}
                        onClick={() => handleTagClick(item.label, 1)}
                      >
                        <img className="my-1" src={item.icon} alt="none" />
                        <p className="my-0">{item.label}</p>
                      </div>
                    ))}
                  </div>
                  <div className="sub1-div5 d-flex justify-content-end mt-4">
                    <p className="text-center text-white px-4 py-1 mb-2">
                      Total Devices: &nbsp; {filteredVehicles?.length}
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="subsub1-sub2-div5 w-100 d-flex justify-content-evenly position-relative align-items-center">
                      <img
                        className="position-absolute"
                        src="/assets/user2.svg"
                        alt="none"
                      />
                      <Select
                        showSearch
                        allowClear
                        className="w-100 text-center"
                        placeholder="Company"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={owners}
                        value={selectedOwner}
                        onChange={(value) => setSelectedOwner(value)}
                      />
                    </div>
                    <input
                      className="filter-input w-100 mt-3 text-center"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="horizontal-bar m-3"></div>
                <div className="device-container overflow-auto px-1">
                  {filteredVehicles?.map((item, index) => {
                    return (
                      <div
                        key={item.deviceImei}
                        id={"veh-" + item.deviceImei}
                        className={`sub1-div6 d-flex my-2 py-3 ${item.deviceImei === selectedVeh && "selected"}`}
                        onClick={() => setSelectedVeh(item.deviceImei)}
                      >
                        <div className="left-sub1-div6 d-flex flex-column justify-content-between align-items-center px-2 py-4">
                          <img src={`/assets/${item.vehicleType}.svg`} alt="none" className="my-2" />
                          <span
                            className="text-white my-1 text-center w-100"
                            style={{ backgroundColor: item.connected ? '#28A745' : '#FF0000' }}
                          >
                            {item?.connected ? 'Connected' : 'Not Connected'}
                          </span>
                        </div>
                        <div className="vertical-bar"></div>
                        <div className="right-sub1-div6 d-flex flex-column px-2 justify-content-between">
                          <div className="d-flex justify-content-between px-2">
                            <span>{item.vehicleName}</span>
                            {item.vehicleType != 'ACS' &&
                              <span>
                                <img src={`/assets/${item.ignition == 1 ? 'engine_on' : 'engine_off'}.svg`} alt="" className="me-3" style={{ width: "1.6rem" }} />
                              </span>
                            }
                          </div>
                          <div className="right-sub1-div6-sub1 d-flex align-items-center">
                            <img src="/assets/calender.png" alt="none" />
                            <p className="mb-0">
                              {formatDateTime(item?.transferDate)}
                            </p>
                          </div>
                          <div className="right-sub1-div6-sub2 d-flex justify-content-between px-2">
                            {item.vehicleType == 'ACS' ?
                              <div style={{ width: "3.5rem" }}>&nbsp;</div> :
                              <Tooltip placement="bottom" title={item.connected ? (item.idle ? 'Idle' : 'Moving') : 'Not Connected'}>
                                <div className="d-flex align-items-center vehicle-status">
                                  {(item.connected && !item.idle) &&
                                    <div className="running-bars">
                                      <div className="running-bar1"></div>
                                      <div className="running-bar2"></div>
                                      <div className="running-bar3"></div>
                                      <div className="running-bar4"></div>
                                      <div className="running-bar5"></div>
                                    </div>
                                  }
                                  <img alt="none" className="cursor-pointer" style={{ height: "1.5rem" }}
                                    src={`/assets/${item.vehicleType.toLowerCase()}_${item.connected ? 'connected' : 'notconnected'}.svg`}
                                  />
                                </div>
                              </Tooltip>
                            }
                            <Popover trigger="click" placement="bottom"
                              overlayClassName="vehicle-info-wrapper"
                              content={(
                                <div className="vehicle-info">
                                  <div>
                                    <span className="fw-bold">
                                      {item.vehicleType == 'ACS' ? 'MAC: ' : 'IMEI: '}
                                    </span>
                                    <span>{item.deviceImei}</span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">Device: </span>
                                    <span>{item.deviceType} - {item.deviceModel}</span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">Coord: </span>
                                    <span className="text-primary cursor-pointer" onClick={(e) => handleLocation(item, e)}>{item.lat}, {item.lng}</span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">Address: </span>
                                    <span className="text-primary cursor-pointer" onClick={(e) => handleLocation(item, e)}>{item.address}</span>
                                  </div>
                                </div>
                              )}
                            >
                              <img
                                src="/assets/I.svg" alt="none" className="cursor-pointer"
                                onClick={(e) => { handleShowInfo(item, e); }}
                              />
                            </Popover>
                            <img
                              src="/assets/Pointer.svg" alt="none" className="cursor-pointer"
                              onClick={(e) => {
                                handleLocation(item, e);
                                hideMobileMenu();
                              }}
                            />
                            <img
                              src="/assets/Location.svg" alt="none" className="cursor-pointer"
                              onClick={(e) => {
                                handleNavigation(item, e);
                                hideMobileMenu();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {selectedVeh && (
                  <div
                    ref={modalRef}
                    className="main-car-portal-side-bar position-absolute"
                  >
                    {selectedVehData?.vehicleType == "ACS" ?
                      <ControlPanelAC
                        selectedVehData={selectedVehData}
                        hidePanel={() => {
                          setSelectedVeh(null);
                          hideMobileMenu();
                        }}
                        setLoadingMsg={setLoadingMsg}
                        setIsLoading={setIsLoading}
                        setKeepControlPanel={(val) => keepControlPanel = val}
                      /> :
                      <ControlPanel
                        selectedVehData={selectedVehData}
                        hidePanel={() => {
                          setSelectedVeh(null);
                          hideMobileMenu();
                        }}
                        setLoadingMsg={setLoadingMsg}
                        setIsLoading={setIsLoading}
                        setKeepControlPanel={(val) => keepControlPanel = val}
                      />
                    }
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex flex-column sidebar">
          <div className="logo py-1 d-flex align-items-end ms-4">
            <img
              src="/assets/logo.png"
              alt="none"
              onClick={() => navigate("/Dashboard")}
            />
          </div>
          <div className="main-sidebar d-flex flex-column h-100 py-4 position-relative">
            <div className="filter px-2 d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center px-3 my-1">
                {connectStatus.map((item) => (
                  <div
                    key={item.id}
                    className={`connected ${connectedTag === item.value && "selected"
                      } d-flex flex-column align-items-center justify-content-center py-1`}
                    style={{ color: item.color }}
                    onClick={() => handleTagClick(item.value, 0)}
                  >
                    <img className="my-1" src={item.icon} alt="none" />
                    <p className="my-0">{item.label}</p>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between align-items-center px-3 my-1">
                {vehicleTypes.map((item) => (
                  <div
                    key={item.id}
                    className={`connected ${vehicleTypeTag === item.label && "selected"
                      } d-flex flex-column align-items-center justify-content-center py-2`}
                    onClick={() => handleTagClick(item.label, 1)}
                  >
                    <img className="my-1" src={item.icon} alt="none" />
                    <p className="my-0">{item.label}</p>
                  </div>
                ))}
              </div>
              <div className="sub1-div5 d-flex justify-content-end me-3 mt-4">
                <p className="text-center text-white px-4 py-1 mb-2">
                  Total Devices: &nbsp; {filteredVehicles?.length}
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="subsub1-sub2-div5 w-100 d-flex justify-content-evenly position-relative align-items-center">
                  <img
                    className="position-absolute"
                    src="/assets/user2.svg"
                    alt="none"
                  />
                  <Select
                    showSearch
                    allowClear
                    className="w-100 text-center"
                    placeholder="Company"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={owners}
                    value={selectedOwner}
                    onChange={(value) => setSelectedOwner(value)}
                  />
                </div>
                <input
                  className="filter-input w-100 mt-3 text-center"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>
            <div className="horizontal-bar m-3"></div>
            <div className="device-container overflow-auto px-2">
              {filteredVehicles?.map((item, index) => {
                return (
                  <div
                    key={item.deviceImei}
                    id={"veh-" + item.deviceImei}
                    className={`sub1-div6 d-flex my-2 py-2 ${item.deviceImei === selectedVeh && "selected"}`}
                    onClick={() => setSelectedVeh(item.deviceImei)}
                  >
                    <div className="left-sub1-div6 d-flex flex-column justify-content-center align-items-center px-2">
                      <img src={`/assets/${item.vehicleType}.svg`} alt="none" className="my-2" />
                      <span className="text-white my-1 text-center w-100" style={{ backgroundColor: item.connected ? '#28A745' : '#FF0000' }}>
                        {item?.connected ? 'Connected' : 'Not Connected'}
                      </span>
                    </div>
                    <div className="vertical-bar"></div>
                    <div className="right-sub1-div6 d-flex flex-column px-2 justify-content-between">
                      <div className="d-flex justify-content-between px-2">
                        <span>{item.vehicleName}</span>
                        {item.vehicleType != 'ACS' &&
                          <span>
                            <Tooltip title="Ignition" placement="bottom">
                              <img src={`/assets/${item.ignition == 1 ? 'engine_on' : 'engine_off'}.svg`} alt="" className="me-3" style={{ width: "1.6rem" }} />
                            </Tooltip>
                          </span>
                        }
                      </div>
                      <div>
                        <div className="right-sub1-div6-sub1 d-flex align-items-center">
                          <img src="/assets/calender.png" alt="none" />
                          <p className="mb-0">
                            {formatDateTime(item?.transferDate)}
                          </p>
                        </div>
                        <div className="right-sub1-div6-sub2 d-flex justify-content-between px-2">
                          {item.vehicleType == 'ACS' ?
                            <div style={{ width: "3.5rem" }}>&nbsp;</div> :
                            <Tooltip placement="bottom" title={item.connected ? (item.idle ? 'Idle' : 'Moving') : 'Not Connected'}>
                              <div className="d-flex align-items-center vehicle-status">
                                {(item.connected && !item.idle) &&
                                  <div className="running-bars">
                                    <div className="running-bar1"></div>
                                    <div className="running-bar2"></div>
                                    <div className="running-bar3"></div>
                                    <div className="running-bar4"></div>
                                    <div className="running-bar5"></div>
                                  </div>
                                }
                                <img alt="none" className="cursor-pointer" style={{ height: "1.5rem" }}
                                  src={`/assets/${item.vehicleType.toLowerCase()}_${item.connected ? 'connected' : 'notconnected'}.svg`}
                                />
                              </div>
                            </Tooltip>
                          }
                          <Popover trigger="click" placement="bottom"
                            overlayClassName={`vehicle-info-wrapper ${item.vehicleType}`}
                            content={(
                              <div className="vehicle-info">
                                <div>
                                  <span className="fw-bold">{item.vehicleType == 'ACS' ? 'MAC:' : 'IMEI:'} </span>
                                  <span>{item.deviceImei}</span>
                                </div>
                                <div>
                                  <span className="fw-bold">Device: </span>
                                  <span>{item.deviceType} - {item.deviceModel}</span>
                                </div>
                                <div>
                                  <span className="fw-bold">Coord: </span>
                                  <span className="text-primary cursor-pointer" onClick={(e) => handleLocation(item, e)}>{item.lat}, {item.lng}</span>
                                </div>
                                <div>
                                  <span className="fw-bold">Address: </span>
                                  <span className="text-primary cursor-pointer" onClick={(e) => handleLocation(item, e)}>{item.address}</span>
                                </div>
                              </div>
                            )}
                          >
                            <Tooltip title="Info" placement="bottom">
                              <img
                                src="/assets/I.svg" alt="none" className="cursor-pointer"
                                onClick={(e) => { handleShowInfo(item, e); }}
                              />
                            </Tooltip>
                          </Popover>
                          <Tooltip title="Show location" placement="bottom">
                            <img
                              src="/assets/Pointer.svg" alt="none" className="cursor-pointer"
                              onClick={(e) => { handleLocation(item, e); }}
                            />
                          </Tooltip>
                          <Tooltip title="Navigate in Googlemap" placement="bottom">
                            <img
                              src="/assets/Location.svg" alt="none" className="cursor-pointer"
                              onClick={(e) => { handleNavigation(item, e); }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {
              selectedVeh && (
                <div
                  ref={modalRef}
                  className="main-car-portal-side-bar position-absolute"
                >
                  {selectedVehData?.vehicleType == "ACS" ?
                    <ControlPanelAC
                      selectedVehData={selectedVehData}
                      hidePanel={() => setSelectedVeh(null)}
                      setLoadingMsg={setLoadingMsg}
                      setIsLoading={setIsLoading}
                      setKeepControlPanel={(val) => keepControlPanel = val}
                    /> :
                    <ControlPanel
                      selectedVehData={selectedVehData}
                      hidePanel={() => setSelectedVeh(null)}
                      setLoadingMsg={setLoadingMsg}
                      setIsLoading={setIsLoading}
                      setKeepControlPanel={(val) => keepControlPanel = val}
                    />
                  }
                </div>
              )
            }
          </div>
        </div >
      )}
    </>
  );
};

export default SideBar;
