import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { addUser, uploadAvatar } from '../../../services/axios';

import "./AddUser.css";
import { UserRole, getManageRoles } from '../../../common/common';
import { Input, Select, notification } from 'antd';
import PhoneNumberInput from '../../commons/input/PhoneNumberInput';
import { getCountries } from "../../../utils/globals";
import { useMediaQuery } from '@mui/material';

const AddUser = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width: 768px)");

    const role = useSelector((state) => state.auth.user.role);
    const CountryData = getCountries();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [avatarPath, setAvatarPath] = useState('');
    const [formData, setFormData] = useState({
        name: "",
        username: "",
        email: "test@mail",
        mobile: "",
        country: "",
        address: "",
        role: "",
        company: "",
        passwd: "",
        cfmpasswd: "",
    })

    const handleData = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value });
    }

    const handleNumber = (value) => {
        setPhoneNumber(value)
    }

    const onChange = async (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            const result = await uploadAvatar(selectedFile);
            if (result.status == 200) {
                setAvatarPath(result.data.filePath);
            } else {
                notification.error({
                    description: "Error occured!",
                });
            }
        }
    }

    const handleSubmit = () => {
        let msg = null;
        if (formData.name.length === 0) {
            msg = "Please input your name!";
        } else if (formData.username.length === 0) {
            msg = "Please input your username!";
        } else if (formData.email.trim().length === 0) {
            msg = "Please input your email!";
        } else if (phoneNumber.length === 0) {
            msg = "Please input your mobile number!";
        } else if (formData.country.length === 0) {
            msg = "Please input your country";
        } else if (formData.address.length === 0) {
            msg = "Please input your address!";
        } else if (formData.role.length === 0) {
            msg = "Please select your role!";
        } else if (formData.company?.length === 0) {
            msg = "Please select your company!";
        } else if (formData.passwd != formData.cfmpasswd) {
            msg = "Password not equal!";
        }
        if (msg != null) {
            notification.warning({
                placement: "topRight",
                description: msg,
                duration: 3,
            });
            return;
        }
        final();
    }

    const final = async () => {
        const sendData = {
            fname: formData.name,
            lname: formData.username,
            email: formData.email.trim(),
            phone: phoneNumber,
            country: formData.country,
            address: formData.address,
            role: formData.role,
            company: formData.company,
            image: avatarPath,
            passwd: formData.passwd,
        }
        var res = await addUser(sendData);
        if (res?.status == 200) {
            notification.success({
                placement: "topRight",
                description: "User has been added successfully!",
                duration: 3,
            });
            setTimeout(() => {
                navigate("/Users");
            }, 1000);
        }
        else {
            notification.error({
                placement: "topRight",
                description: res?.data.message,
                duration: 3,
            });
        }
    }

    return (
        <div className="add-user-main w-100 h-100">
            <p>Add User</p>
            <form autoComplete="none" onSubmit={e => e.preventDefault()}>
                <div className='add-user-div1 d-flex justify-content-start flex-column p-5 mx-5'>
                    <div className='person-add-user-sub1-add-user-div1 d-flex justify-content-center position-relative mb-5'>
                        <input type='file' className='personfile position-absolute' accept='image/*' onChange={onChange} />
                        <img crossOrigin='*' src={avatarPath ? process.env.REACT_APP_URL + '/' + avatarPath : '/assets/common_user.png'} alt='none' className='person-add-user object-fit-cover' />
                    </div >
                    <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
                        <div className='d-flex flex-column'>
                            <label>Name</label>
                            <input className='normal-input' type='text' value={formData.name} onChange={e => handleData(e, 'name')} required autoComplete="new-fname2" />
                        </div>
                        <div className='sub2-subsub1-sub1-add-user-div1 d-flex flex-column position-relative'>
                            <label>Username</label>
                            <input className='normal-input' type='text' name='new-lname2' value={formData.username} onChange={(e) => handleData(e, 'username')} required autoComplete="new-lname2" />
                            <input className='position-absolute d-flex justify-content-center align-items-center' type='checkbox' />
                        </div>
                    </div>
                    <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
                        <div className='d-flex flex-column'>
                            <label>Email</label>
                            <input className='normal-input' type='email' name='asd23asdf23' id="asdf23asdf3" value={formData.email} onChange={e => handleData(e, 'email')} required autoComplete="new-email2" />
                        </div>
                        <div className='d-flex flex-column'>
                            <label>Mobile No.</label>
                            <div className='normal-input border-0 p-0'>
                                <PhoneNumberInput
                                    value={phoneNumber}
                                    onChange={handleNumber}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
                        <div className='d-flex flex-column position-relative'>
                            <label>Country & City</label>
                            <div className="normal-input p-0 border-0">
                                <Select
                                    showSearch
                                    allowClear
                                    size={isMobile ? "small" : "large"}
                                    className="w-100"
                                    placeholder="City & Country"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={CountryData?.map((item) => ({
                                        label: `${item.city}, ${item.country}`, value: `${item.city}, ${item.country}`
                                    }))}
                                    value={formData.country}
                                    onChange={(value) => setFormData({ ...formData, "country": value })}
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-column'>
                            <label>Address</label>
                            <input
                                className='normal-input' name='address-4-add' type='text' value={formData.address} onChange={e => handleData(e, 'address')} autoComplete='new-address2'
                            />
                        </div>
                    </div>
                    <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
                        <div className='d-flex flex-column'>
                            <label>User Type</label>
                            <select className='normal-input' type='text' value={formData.role} onChange={e => handleData(e, 'role')}>
                                <option></option>
                                {getManageRoles(role).map(item => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <div className='d-flex flex-column'>
                            <label>Company</label>
                            <select className='normal-input' type='text' value={formData.company} onChange={e => handleData(e, 'company')} required>
                                <option value="">Select Company</option>
                                {location?.state?.companies && location?.state?.companies.map(item => <option key={item._id} value={item._id}>{item.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
                        <div className='d-flex flex-column'>
                            <label>Password</label>
                            <Input.Password size='large' className='normal-input' value={formData.passwd} onChange={e => handleData(e, 'passwd')} autoComplete='new-passwd2' />
                        </div>
                        <div className='sub2-subsub1-sub1-add-user-div1 d-flex flex-column position-relative'>
                            <label>Confirm Password</label>
                            <Input.Password size='large' className='normal-input' value={formData.cfmpasswd} onChange={e => handleData(e, 'cfmpasswd')} autoComplete='new-cfmpasswd2' />
                        </div>
                    </div>
                    <div className='subsub5-sub1-add-user-div1 d-flex justify-content-center' >
                        <button type='button' className='me-5' onClick={() => navigate("/Users")}>Cancel</button>
                        <button type='button' onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddUser;
