import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Login from "./component/auth/login/Login";
import Signup from "./component/auth/signUps/Signup";
import Forgot from "./component/auth/forgot/Forgot";

import Dashboard from "./component/dashboard/Dashboard";
import CommandsSettings from "./component/commads-settings/CommandsSettings";
import Telemetry from "./component/telemetry/Telemetry";
import Traffic from "./component/traffic/Traffic";

import Users from "./component/users/Users";
import AddUser from "./component/users/addUser/AddUser";
import ViewUser from "./component/users/viewUser/ViewUser";
import EditUser from "./component/users/editUser/EditUser";
import EditMe from "./component/users/editMe/EditMe";

import Devices from "./component/devices/Devices";
import AddDevice from "./component/devices/addDevice/AddDevice";
import EditDevice from "./component/devices/editDevice/EditDevice";
import ViewDevice from "./component/devices/viewDevice/ViewDevice";
import AddDeviceAC from "./component/devices/addDevice/AddDeviceAC";
import EditDeviceAC from "./component/devices/editDevice/EditDeviceAC";
import ViewDeviceAC from "./component/devices/viewDevice/ViewDeviceAC";

import Geofence from "./component/geofence/Geofence";
import Rules from "./component/rules/Rules";
import Alerts from "./component/alerts/Alerts";
import Reports from "./component/reports/Reports";
import History from "./component/history/History";
import Ticket from "./component/tickets/Ticket";

import Company from "./component/company/Company";
import AddCompany from "./component/company/addCompany/AddCompany";
import ViewCompany from "./component/company/viewCompany/ViewCompany";
import EditCompany from "./component/company/editCompany/EditCompany";

import TerminalStatus from "./component/acs/TerminalStatus";
import ACSUserList from "./component/acs/UserList";
import ACSUserEdit from "./component/acs/UserEdit";
import AccessLogs from "./component/acs/AccessLogs";

import "./App.css";

const MainRoutes = () => {
  const location = useLocation();
  const navicate = useNavigate();
  useEffect(() => {
    // if (location.pathname === "/") {
    //   navicate("/");
    // }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/Signup" element={<Signup />} />
      <Route path="/Forgot" element={<Forgot />} />

      <Route path="/UpdateProfile" element={<EditMe />} />

      <Route path="/Dashboard" element={<Dashboard />} />
      <Route path="/CommandsSettings" element={<CommandsSettings />} />
      <Route path="/Telemetry" element={<Telemetry />} />
      <Route path="/Traffic" element={<Traffic />} />
      <Route path="/History" element={<History />} />
      <Route path="/Geofence" element={<Geofence />} />
      <Route path="/Ticket" element={<Ticket />} />

      <Route path="/Users" element={<Users />} />
      <Route path="/Users/AddUser" element={<AddUser />} />
      <Route path="/Users/ViewUser" element={<ViewUser />} />
      <Route path="/Users/EditUser" element={<EditUser />} />

      <Route path="/Devices" element={<Devices />} />
      <Route path="/Devices/AddDevice" element={<AddDevice />} />
      <Route path="/Devices/AddDeviceAC" element={<AddDeviceAC />} />
      <Route path="/Devices/ViewDevice" element={<ViewDevice />} />
      <Route path="/Devices/ViewDeviceAC" element={<ViewDeviceAC />} />
      <Route path="/Devices/EditDevice" element={<EditDevice />} />
      <Route path="/Devices/EditDeviceAC" element={<EditDeviceAC />} />

      <Route path="/Rules" element={<Rules />} />
      <Route path="/Alerts" element={<Alerts />} />
      <Route path="/Reports" element={<Reports />} />

      <Route path="/Company" element={<Company />} />
      <Route path="/Company/AddCompany" element={<AddCompany />} />
      <Route path="/Company/ViewCompany" element={<ViewCompany />} />
      <Route path="/Company/EditCompany" element={<EditCompany />} />

      <Route path="/acs/TerminalStatus" element={<TerminalStatus />} />
      <Route path="/acs/UserList" element={<ACSUserList />} />
      <Route path="/acs/AccessLogs" element={<AccessLogs />} />
    </Routes>
  );
};

export default MainRoutes;
